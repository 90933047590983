<template>
	<div :ref="'graph'+start.id">
		<div :ref="'tooltip'+start.id"/>
		<svg class="graph" :ref="'svg'+start.id" :height="height">
			<g :ref="'edges'+start.id" />
			<g :ref="'nodes'+start.id" />
		</svg>
	</div>
</template>
<script>
	import * as d3 from "d3";

	export default {
		name: "StatusGraph",
		props: ["status", "itemStatus", "refresh", "start", "pHeight", "pWidth"],
		data() {
			return {
				ready: false,
				svg: null,
				graph: null,
				nodes: null,
				edges: null,
				cols: [],
				diameter: 40,
				height: null,
				width: null,
				max_height: null,
				tooltip_size: 40
			};
		},
		computed: {
			status_list() {
				return this.status.choices;
			}
		},
		watch: {
			refresh() {
				this.init();
				this.reset()
				setTimeout(this.draw, 1000)
			},
			status_list(val) {
				if (val) {
					this.init();
					this.reset()
					setTimeout(this.draw, 1000)
				}
			},
			"$vuetify.breakpoint.width"(){
				this.reset()
				setTimeout(this.draw, 1000)
			}
		},
		methods: {
			annimateEdgeOverlay(d3Obj, size){
				d3Obj
					.transition()

					.on("start", function repeat(){
						d3.active(this)
							.transition()
							.ease(d3.easeLinear)
							.duration(1000)
							.attr("stroke-dashoffset", 0)
							.transition()
							.duration(0)
							.attr("stroke-dashoffset", size)
							.on("end", repeat)
					})
			},
			annimateEdge(d3Obj,size, delay, loop){
				d3Obj.attr("stroke-dasharray",  size + " " + size)
					.attr("stroke-dashoffset", size)
					.transition()
					.delay(delay)
					.duration(1000)
					.attr("stroke-dashoffset", 0)
					.on("end",()=>{
						if(loop){
							var line_overlay = d3Obj.clone()
								.attr("stroke", "var(--v-primary-lighten2)")
								.attr("opacity", 0)
								.attr("stroke-dasharray",size/2 +" " +size/2)
								.attr("stroke-dashoffset", size/2)
								.transition()
								.duration(100)
								.attr("opacity", 1)
								.on("end", ()=>{
									this.annimateEdgeOverlay(line_overlay, size)
								})
						}
					})
			},
			annimateNode(d3Obj, delay){
				var totalLength = d3Obj.attr("r");

				d3Obj.attr("r", 0)
					.transition()
					.attr("r", totalLength)
					.delay(delay)
					.duration(1000)
			},
			annimateAny(d3Obj, delay){
				d3Obj.attr("opacity", 0)
					.transition()
					.attr("opacity", 1)
					.delay(delay)
					.duration(500)
			},
			buildCols(collumn, index) {
				let next_col = [];

				if (collumn.length > 0) {
					collumn.forEach((item) => {
						item.next.forEach((n) => {
							let nextItem = this.status_list.find((s) => s.id === n.id);
							this.cols.forEach((c) => {
								let itemIndex = c.findIndex((e) => e.id === nextItem.id);
								if (itemIndex > 0) c.splice(itemIndex, 1);
							});
							if (next_col.findIndex(e => e.id === nextItem.id) === -1){
								next_col.push({
									id: nextItem.id,
									next: nextItem.next,
									data: nextItem,
									selected: this.itemStatus.some((e) => e.id === nextItem.id),
								});
							}
						});
					});

					this.cols.push(next_col);
					this.buildCols(next_col, index++);
				}
			},
			reset(){
				this.edges = d3.select(this.$refs["edges"+this.start.id]);
				this.nodes = d3.select(this.$refs["nodes"+this.start.id]);
				this.graph = d3.select(this.$refs["graph"+this.start.id]);
				this.tooltip = d3.select(this.$refs["tooltip"+this.start.id]);
				this.svg = d3.select(this.$refs["svg"+this.start.id]);
				this.tooltip.selectAll("*").remove()
				this.edges.selectAll("*").remove()
				this.nodes.selectAll("*").remove()
			},
			init(){
				this.ready = false

				this.cols = [
					[
						{
							id: this.start.id,
							next: this.start.next,
							selected: this.itemStatus.some((e) => e.id === this.start.id),
							data: this.start,
						},
					],
				];

				this.cols.forEach(this.buildCols);
			},
			initSizes(){
				const lengths = this.cols.map(a => a.length);
				this.max_height = this.cols[lengths.indexOf(Math.max(...lengths))].length
				this.height = Math.min(400, this.svg.node().clientWidth / 1.5)
				this.width = Math.max(this.svg.node().clientWidth, this.height)
				this.stepX = (this.width / this.cols.length )
				this.stepY = (this.height / this.max_height)
				this.diameter = Math.min(Math.min(this.stepX /2.5, this.stepY/2.5), this.width /20) 
			},
			draw(){
				setTimeout(this.drawCall, 400)
			},
			drawCall() {
				this.initSizes()

				this.cols.forEach((collumn, i) => {
					collumn.forEach((item, j) => {
						item.x = parseInt((i + 1) * this.stepX)
						let stepY = (this.height / collumn.length)
						item.y = parseInt((j + 0.5) * stepY)
						item.r = this.diameter / 2;
						this.drawNode(item, i);
					});
				});
				this.cols.forEach((list, i) =>
					list.forEach((item, j) =>
						item.data.next.forEach((next) => {
							let nextItem = null;
							this.cols.forEach((collumn) => {
								let n = collumn.find((e) => e.id === next.id);
								if (n) {
									this.drawEdge(item, n, i)
								}
							});
						})
					)
				);
				this.$emit('ready', this.max_height)
				this.ready = true
			},
			drawNode(item, col) {
				let tooltip = this.tooltip.append("div")
					.attr("class", "tooltip2")
					.style("width", 4*this.tooltip_size +"px")
					.style("height", 1.5*this.tooltip_size +"px")
					.style("border-radius", this.tooltip_size/8 +"px")
					.style("font-size",  this.tooltip_size/4 + "px")
					.style("background", this.$tools.transparentize(item.selected ? "primary" : "label", 0.8, this.$vuetify))
					.style("color", "white")
					.text(item.data.display_name)
				tooltip.append("div")
					.attr("class", "tooltip-arrow")
					.style("background", this.$tools.transparentize(item.selected ? "primary" : "label", 0.8, this.$vuetify))
					.style("width", this.tooltip_size/2 +"px")
					.style("height", this.tooltip_size/2 +"px")
					.style("top", -this.tooltip_size/2 +"px")
					.style("left", 1.75*this.tooltip_size +"px")
				let group = this.nodes.append("g")


				let node = group.append("circle")
					.attr("r", item.r)
					.attr("fill", item.selected ? "var(--v-primary-base)" : "var(--v-label-darken2)")
					.attr("cx", item.x)
					.attr("cy", item.y)
				let text = group.append("text")
					.attr("x", item.x)
					.attr("y", item.y)
					.attr("font-size",  item.r)
					.attr("fill", item.selected ? "var(--v-primary-lighten4)" : "var(--v-label-lighten4)")
					.text(item.data.display_name[0])
					.attr("style", "text-anchor: middle; dominant-baseline: central")
				group.append("circle")
					.attr("fill", "transparent")
					.attr("cx", item.x)
					.attr("cy", item.y)
					.attr("r", item.r+10)
					.style("z-index",  15)
					.style("cursor", "pointer")
					.on("mouseover", (event)=>{
						node.transition()
							.duration(500)
							.attr("r", item.r*1.3)
						return tooltip
							.style("top", (event.y+this.tooltip_size )+"px")
							.style("left", (event.x-2*this.tooltip_size)+"px")
							.style("opacity", 1)
							.style("visibility", "visible")

					})
					.on("mousemove", (event)=>{
						return tooltip
							.style("top", (event.y+this.tooltip_size )+"px")
							.style("left", (event.x-2*this.tooltip_size)+"px");
					})
					.on("mouseout", (event)=>{
						node.transition()
							.duration(500)
							.attr("r", item.r)
						return tooltip
							.style("top", (event.y+this.tooltip_size )+"px")
							.style("left", (event.x-2*this.tooltip_size)+"px")
							.style("opacity", 0)
							.style("visibility", "hidden")
					})
				this.annimateAny(text, col*100)
				this.annimateNode(node, col*100)
			},
			drawEdge(node1, node2, col) {
				let r = this.diameter / 2;
				let edge = this.edges.append("g");
				let angle = -90 + (Math.atan((node2.y - node1.y) / (node2.x - node1.x)) * 180) / Math.PI;
				let size = Math.sqrt(Math.pow(node2.x - node1.x,2) + Math.pow(node2.y - node1.y,2))

				let line = edge.append("line")
					.attr("x1", node1.x)
					.attr("y1", node1.y)
					.attr("x2", node2.x)
					.attr("y2", node2.y)
					.attr("stroke", this.itemStatus.some((e) => e.id === node1.id) ? "var(--v-primary-base)" : "var(--v-label-base)")
					.attr("stroke-width", r / 4)
				this.annimateEdge(line,size, 100+100*col, this.itemStatus.some((e) => e.id === node1.id) && !this.itemStatus.some((e) => e.id === node2.id))

				let arrow = edge.append("polygon")
				arrow.attr("points",
					" " +(node2.x - r / 3).toString() +", " +(node2.y - r / 3).toString() +
					" " +node2.x.toString() +", " +(node2.y + r / 3).toString() +
					" " +(node2.x + r / 3).toString() +", " +(node2.y - r / 3).toString()
				)
					.attr("fill",  this.itemStatus.some((e) => e.id === node1.id) ? "var(--v-primary-base)" : "var(--v-label-base)")
					.attr(
						"transform",
						"rotate(" + angle + " " + node2.x.toString() + " " + node2.y.toString() + ") translate(0 " + -r * 1.1 + ")"
					);
				this.annimateAny(arrow, 500 + 100*col)
			},
		},
		mounted() {
			this.init();
			this.reset();
			setTimeout(this.draw, 1000)
		},
	};
</script>

<style>
	.tooltip-arrow{
		display: block;
		position: absolute;
		bottom: -1px;
		clip-path: polygon(50% 0, 0 100%, 100% 100%);
	}
	.tooltip2{
		transition: opacity 500ms, visibility 500ms;
		position: fixed;
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		word-wrap:  break-word;
		opacity: 0;
		z-index: 10;
	}
	.graph{
		display: flex;
		width:100%;
	}
</style>
